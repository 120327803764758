import { i18nUtil } from '@/utils'
import { SubLibraryStatistics } from '../../subLibrary-statistics'

// 基因的数量统计
export const GeneCount: SubLibraryStatistics[] = [
  { title: i18nUtil.T('gene.gene'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('gene.bioproject'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('gene.biosample'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('gene.nucleotide'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('gene.clinvar'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('gene.nlp'), span: 4, number: 0, updateNumber: 0 },
]

// 化合物统计
export const CompoundCount: SubLibraryStatistics[] = [
  { title: i18nUtil.T('compound.compound'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('compound.chemicalreaction'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('compound.bioassay'), span: 4, number: 0, updateNumber: 0 },
]

//蛋白质
export const ProteinCount: SubLibraryStatistics[] = [
  { title: i18nUtil.T('protein.protein'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('protein.target'), span: 4, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('protein.Statisitc.Protein3D'), span: 3, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('protein.Statisitc.ProteinInteraction'), span: 3, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('protein.Statisitc.HumanDisease'), span: 3, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('protein.Statisitc.HomologyIsomers'), span: 3, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('protein.Statisitc.DrugTargetInteraction'), span: 4, number: 0, updateNumber: 0 },
]

// 天然产物
export const NatureCount: SubLibraryStatistics[] = [
  { title: i18nUtil.T('nature.compound'), span: 5, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('nature.bioassay'), span: 5, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('nature.food'), span: 5, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('nature.plant'), span: 5, number: 0, updateNumber: 0 },
  { title: i18nUtil.T('nature.cell'), span: 4, number: 0, updateNumber: 0 },
]
