import request from '@/utils/request'

interface BaseStatistic {
  recentCount: number
  allCount: number
}
export interface CompoundStatistic {
  compound: BaseStatistic
  chemicalReaction: BaseStatistic
  bioassay: BaseStatistic
}

// 化合物统计
export const CompoundStatistic = () => {
  return request<CompoundStatistic>(`/detail/compound/count/statistics`, 'get')
}
