import request from '@/utils/request'

export interface GeneStatistic {
  geneCount: number
  nucleotideCount: number
  bioprojectCount: number
  biosampleCount: number
  clinvarCount: number
  nlpCount: number
  geneUpdate: number
  bioprojectUpdate: number
  biosampleUpdate: number
  clinvarUpdate: number
  nucleotideUpdate: number
}

export const GeneStatistic = () => {
  return request<GeneStatistic>(`/search/gene/totalNumber`, 'get')
}
