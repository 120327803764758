import request from '@/utils/request'

export interface BaseProteinStatistic {
  protein: number
  target: number
  struct: number
  interaction: number
  diseases: number
  iso: number
  medicine: number
}
export interface ProteinStatistic {
  timestamp: string
  all: BaseProteinStatistic
  update: BaseProteinStatistic
}

// 化合物统计
export const ProteinStatistic = (data: { timestamp: string }) => {
  return request<ProteinStatistic>(`/detail/protein/statistics`, 'get', data)
}
