<template>
  <div class="subLibrary-statistics">
    <el-row>
      <el-col v-for="(item, index) in statistics" :key="index" :span="item.span">
        <div class="main">
          <div class="main-top">
            <span class="main-top-title">{{ item.title }}</span>
            <img :src="item.img || 'https://yyhome2022.oss-cn-hangzhou.aliyuncs.com/img/ascending.svg'" />
            <span class="text-xs">{{ item.updateNumber }}</span>
          </div>
          <div class="main-number">{{ changeArr[index]?.number.toFixed(0) }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref } from 'vue'
import { SubLibraryStatistics } from './'
import gsap from 'gsap'

const props = defineProps<{
  statistics: SubLibraryStatistics[]
}>()

const changeArr = ref<{ number: number; color: string }[]>([])

watch(
  () => props.statistics,
  (val) => {
    val.forEach((item, index) => {
      changeArr.value.push({ number: 0, color: '#333' })
      gsap.to(changeArr.value[index], {
        number: item.number,
        duration: 3,
        color: '#333',
      })
    })
  },
  {
    deep: true,
  }
)
</script>

<style scoped lang="scss">
.subLibrary-statistics {
  background-color: rgb(245, 247, 250);
  padding: 7px 0 7px 0;

  .main {
    border-left: #006bc6 solid 3px;
    padding-left: 20px;
    height: 50px;

    &-top {
      display: flex;
      align-items: center;

      &-title {
        color: #999;
        font-size: 14px;
      }
    }

    &-number {
      color: #333;
      font-weight: 600;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    }
  }
}
</style>
