<!-- 此处主要是用来集中四个字库的统计 -->
<template>
  <SubLibraryStatistics :statistics="dataList" />
</template>

<script lang="ts" setup>
import { watch } from 'vue'
import SubLibraryStatistics from '../../subLibrary-statistics/index.vue'
import { TagType } from '@/components/BaseLayout'
import Service from './index'

const prop = defineProps<{
  tag: TagType
}>()

const { dataList, fetchStatistics } = new Service()

watch(
  () => prop.tag,
  async (val) => {
    await fetchStatistics(val)
  },
  { deep: true, immediate: true }
)
</script>

<script lang="ts">
export default {
  name: 'StatisticsComp',
}
</script>

<style scoped lang="scss"></style>
.
