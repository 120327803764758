import request from '@/utils/request'

export interface NatureStatistic {
  cellUpdate: number
  bioassayUpdate: number
  foodUpdate: number
  foodCount: number
  plantCount: number
  cellCount: number
  plantUpdate: number
  compoundCount: number
  bioassayCount: number
}

export const NatureStatistic = () => {
  return request<NatureStatistic>(`/detail/nature/recent/information`, 'get')
}
