import { ref } from 'vue'
import { SubLibraryStatistics } from '../../subLibrary-statistics'
import { CompoundStatistic, ProteinStatistic, GeneStatistic, NatureStatistic } from '@/api_new/modules'

import { GeneCount, CompoundCount, ProteinCount, NatureCount } from './type'
import { TagType } from '@/components/BaseLayout'
// 请求方法映射

export default class Service {
  dataList = ref<SubLibraryStatistics[]>([])
  // TODO 获取数据 【有更好的处理办法的】
  fetchStatistics = async (val: TagType) => {
    switch (val) {
      case 'Gene':
        const { data: Gene } = await GeneStatistic()
        this.dataList.value = this.handleGeneStatistic(Gene)
        break
      case 'Compound':
        const { data: Compound } = await CompoundStatistic()
        this.dataList.value = this.handleCompoundStatistic(Compound)
        break
      case 'Protein':
        const { data: Protein } = await ProteinStatistic({ timestamp: '2023-5-25' })
        this.dataList.value = this.handleProteinStatistic(Protein)
        break
      case 'Nature':
        const { data: Nature } = await NatureStatistic()
        this.dataList.value = this.handleNatureStatistic(Nature)
        break
    }
  }
  // 处理基因的
  private handleGeneStatistic = (result: GeneStatistic) => {
    return GeneCount.map((item, index) => {
      // 没办法避免了
      switch (index) {
        case 0:
          this.handleItemFn(item, result.geneCount, result.geneUpdate || 0)
          break
        case 1:
          this.handleItemFn(item, result.bioprojectCount, result.bioprojectUpdate || 0)
          break
        case 2:
          this.handleItemFn(item, result.biosampleCount, result.biosampleUpdate || 0)
          break
        case 3:
          this.handleItemFn(item, result.nucleotideCount, result.nucleotideUpdate || 0)
          break
        case 4:
          this.handleItemFn(item, result.clinvarCount, result.clinvarUpdate || 0)
          break
        case 5:
          this.handleItemFn(item, result.nlpCount, result.nucleotideUpdate || 0)
          break
      }
      return item
    })
  }
  // 处理化合物的
  private handleCompoundStatistic = (result: CompoundStatistic) => {
    return CompoundCount.map((item, index) => {
      // 没办法避免了
      switch (index) {
        case 0:
          this.handleItemFn(item, result.compound.allCount, result.compound.recentCount)
          break
        case 1:
          this.handleItemFn(item, result.chemicalReaction.allCount, result.chemicalReaction.recentCount)
          break
        case 2:
          this.handleItemFn(item, result.bioassay.allCount, result.bioassay.recentCount)
          break
      }
      return item
    })
  }
  // 处理蛋白质
  private handleProteinStatistic = (result: ProteinStatistic) => {
    return ProteinCount.map((item, index) => {
      // 没办法避免了
      switch (index) {
        case 0:
          this.handleItemFn(item, result.all.protein, result.update.protein)
          break
        case 1:
          this.handleItemFn(item, result.all.target, result.update.target)
          break
        case 2:
          this.handleItemFn(item, result.all.struct, result.update.struct)
          break
        case 3:
          this.handleItemFn(item, result.all.interaction, result.update.interaction)
          break
        case 4:
          this.handleItemFn(item, result.all.diseases, result.update.diseases)
          break
        case 5:
          this.handleItemFn(item, result.all.iso, result.update.iso)
          break
        case 6:
          this.handleItemFn(item, result.all.medicine, result.update.medicine)
          break
      }
      return item
    })
  }
  // 处理天然产物的
  private handleNatureStatistic = (result: NatureStatistic) => {
    return NatureCount.map((item, index) => {
      // 没办法避免了
      switch (index) {
        case 0:
          this.handleItemFn(item, result.compoundCount, 0)
          break
        case 1:
          this.handleItemFn(item, result.bioassayCount, result.bioassayUpdate)
          break
        case 2:
          this.handleItemFn(item, result.foodCount, result.foodUpdate)
          break
        case 3:
          this.handleItemFn(item, result.plantCount, result.plantUpdate)
          break
        case 4:
          this.handleItemFn(item, result.cellCount, result.cellUpdate)
          break
      }
      return item
    })
  }
  // 处理每一项的
  private handleItemFn(item: SubLibraryStatistics, count: number, update: number) {
    item.number = count
    item.updateNumber = update
  }
}
